.gallery-container {
  margin: -2px;
  height: 100%;
}

.image-gallery-content.fullscreen .image-gallery-image {
  height: 100vh;
  max-height: 100vh;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}

.image-gallery.fullscreen-modal {
  z-index: 10000;
}

.custom-gallery-content,
.mobile-gallery-content {
  position: relative;
  z-index: 1;
}

.custom-gallery-content
  .image-gallery-content:not(.fullscreen)
  .image-gallery-slide-wrapper {
  padding-bottom: 40px;
}

.custom-gallery-content
  .image-gallery-content:not(.fullscreen)
  .image-gallery-image {
  height: 100vh;
  max-height: calc(100vh - 80px);
}

.custom-gallery-content {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
}

.close-button {
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.7);
  justify-content: flex-end;
  padding-right: 16px;
  cursor: pointer;
}

.close-button:hover {
  color: #337ab7;
}

.custom-gallery-content .image-gallery-index {
  background: transparent;
  top: auto;
  bottom: 10px;
  position: absolute;
  right: 50%;
  font-weight: 600;
}

.custom-gallery-content .image-gallery-left-nav .image-gallery-svg,
.custom-gallery-content .image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 60px;
}

@media (min-height: 768px) {
  .custom-gallery-content
    .image-gallery-content:not(.fullscreen)
    .image-gallery-image {
    max-height: calc(100vh - 300px);
    padding-top: 50px;
  }

  .custom-gallery-content
    .image-gallery-content:not(.fullscreen)
    .image-gallery-slide-wrapper {
    padding-bottom: 70px;
  }

  .close-button {
    padding-top: 110px;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .mobile-gallery-content
    .image-gallery-content:not(.fullscreen)
    .image-gallery-image {
    /*
    * keep it above the fold (consider address bar)
    * leave 100px for top navbar and 100px for bottom navbar
    */
    max-height: calc(var(--window-available-height, 100vh) - 200px);
  }
}
