@font-face {
  font-family: 'Poppins';
  src: local('Poppins Light'), local('Poppins-Light'),
    url(./assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url(./assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url(./assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: local('FrankRuhlLibre Light'), local('FrankRuhlLibre-Light'),
    url(./assets/fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Light.ttf)
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: local('FrankRuhlLibre Regular'), local('FrankRuhlLibre-Regular'),
    url(./assets/fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Regular.ttf)
      format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: local('FrankRuhlLibre Medium'), local('FrankRuhlLibre-Medium'),
    url(./assets/fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Medium.ttf)
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: local('FrankRuhlLibre Black'), local('FrankRuhlLibre-Black'),
    url(./assets/fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Black.ttf)
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: local('FrankRuhlLibre Bold'), local('FrankRuhlLibre-Bold'),
    url(./assets/fonts/Frank_Ruhl_Libre/FrankRuhlLibre-Bold.ttf)
      format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin Pro';
  src: local('AcuminPro Regular'), local('AcuminPro-Regular'),
    url(./assets/fonts/Acumin_Pro/AcuminPro-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Acumin Pro';
  src: local('AcuminPro Bold'), local('AcuminPro-Bold'),
    url(./assets/fonts/Acumin_Pro/AcuminPro-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LabelIconFont';
  src: url(./assets/fonts/LabelIconFont/LabelIconFont.eot?7mm0k3);
  src: url(./assets/fonts/LabelIconFont/LabelIconFont.eot?7mm0k3#iefix)
      format('embedded-opentype'),
    url(./assets/fonts/LabelIconFont/LabelIconFont.ttf?7mm0k3)
      format('truetype'),
    url(./assets/fonts/LabelIconFont/LabelIconFont.woff?7mm0k3) format('woff'),
    url(./assets/fonts/LabelIconFont/LabelIconFont.svg?7mm0k3#LabelIconFont)
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  background-color: #000014;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'LabelIconFont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 32px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p i {
  font-family: 'Poppins' !important;
  font-size: inherit;
  font-style: italic;
}

.icon-ada-accessible .path1:before {
  content: '\e900';
  color: rgb(247, 247, 248);
}

.icon-ada-accessible .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-ada-accessible .path3:before {
  content: '\e902';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-ada-restrooms .path1:before {
  content: '\e903';
  color: rgb(247, 247, 248);
}

.icon-ada-restrooms .path2:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-ada-restrooms .path3:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-ada-restrooms .path4:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-additional-electric-tie-in .path1:before {
  content: '\e907';
  color: rgb(247, 247, 248);
}

.icon-additional-electric-tie-in .path2:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-amenities-restrooms .path1:before {
  content: '\e909';
  color: rgb(247, 247, 248);
}

.icon-amenities-restrooms .path2:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-art-exhibition .path1:before {
  content: '\e90b';
  color: rgb(247, 247, 248);
}

.icon-art-exhibition .path2:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-attended-lobby .path1:before {
  content: '\e90d';
  color: rgb(247, 247, 248);
}

.icon-attended-lobby .path2:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-av-capabilities .path1:before {
  content: '\e90f';
  color: rgb(247, 247, 248);
}

.icon-av-capabilities .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-back-of-house-space .path1:before {
  content: '\e911';
  color: rgb(247, 247, 248);
}

.icon-back-of-house-space .path2:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-back-of-house-space .path3:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-gala .path1:before {
  content: '\e914';
  color: rgb(247, 247, 248);
}

.icon-gala .path2:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-brand-activation .path1:before {
  content: '\e916';
  color: rgb(247, 247, 248);
}

.icon-brand-activation .path2:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-ceremony .path1:before {
  content: '\e918';
  color: rgb(247, 247, 248);
}

.icon-ceremony .path2:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-column-free .path1:before {
  content: '\e91a';
  color: rgb(247, 247, 248);
}

.icon-column-free .path2:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-concert .path1:before {
  content: '\e91c';
  color: rgb(247, 247, 248);
}

.icon-concert .path2:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-conference .path1:before {
  content: '\e91e';
  color: rgb(255, 255, 255);
}

.icon-conference .path2:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-conference .path3:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-conference .path4:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-conference .path5:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-conference .path6:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-conference .path7:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-conference .path8:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-conference .path9:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-corporate-dinner .path1:before {
  content: '\e927';
  color: rgb(247, 247, 248);
}

.icon-corporate-dinner .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path3:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path4:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path5:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path6:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path7:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path8:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-dinner .path9:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path1:before {
  content: '\e930';
  color: rgb(247, 247, 248);
}

.icon-corporate-party .path2:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path3:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path4:before {
  content: '\e933';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path5:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path6:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path7:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path8:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path9:before {
  content: '\e938';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-corporate-party .path10:before {
  content: '\e939';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-custom-light .path1:before {
  content: '\e93a';
  color: rgb(247, 247, 248);
}

.icon-custom-light .path2:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-dressing-rooms .path1:before {
  content: '\e93c';
  color: rgb(247, 247, 248);
}

.icon-dressing-rooms .path2:before {
  content: '\e93d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-elevator .path1:before {
  content: '\e93e';
  color: rgb(247, 247, 248);
}

.icon-elevator .path2:before {
  content: '\e93f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-experiential .path1:before {
  content: '\e940';
  color: rgb(247, 247, 248);
}

.icon-experiential .path2:before {
  content: '\e941';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-experiential .path3:before {
  content: '\e942';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-fashion .path1:before {
  content: '\e943';
  color: rgb(247, 247, 248);
}

.icon-fashion .path2:before {
  content: '\e944';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path1:before {
  content: '\e945';
  color: rgb(247, 247, 248);
}

.icon-film-photo .path2:before {
  content: '\e946';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path3:before {
  content: '\e947';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path4:before {
  content: '\e948';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path5:before {
  content: '\e949';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path6:before {
  content: '\e94a';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path7:before {
  content: '\e94b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path8:before {
  content: '\e94c';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path9:before {
  content: '\e94d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-film-photo .path10:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-freight-elevator .path1:before {
  content: '\e94f';
  color: rgb(247, 247, 248);
}

.icon-freight-elevator .path2:before {
  content: '\e950';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-furniture .path1:before {
  content: '\e951';
  color: rgb(247, 247, 248);
}

.icon-furniture .path2:before {
  content: '\e952';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-gallery .path1:before {
  content: '\e955';
  color: rgb(247, 247, 248);
}

.icon-gallery .path2:before {
  content: '\e956';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-gallery .path3:before {
  content: '\e957';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-gender-neutral-restrooms .path1:before {
  content: '\e958';
  color: rgb(247, 247, 248);
}

.icon-gender-neutral-restrooms .path2:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-glass-frontage .path1:before {
  content: '\e95a';
  color: rgb(247, 247, 248);
}

.icon-glass-frontage .path2:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-high-ceilings .path1:before {
  content: '\e95c';
  color: rgb(247, 247, 248);
}

.icon-high-ceilings .path2:before {
  content: '\e95d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-holiday-party .path1:before {
  content: '\e95e';
  color: rgb(247, 247, 248);
}

.icon-holiday-party .path2:before {
  content: '\e95f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-hvac .path1:before {
  content: '\e960';
  color: rgb(247, 247, 248);
}

.icon-hvac .path2:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-in-house-catering .path1:before {
  content: '\e962';
  color: rgb(247, 247, 248);
}

.icon-in-house-catering .path2:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-in-house-catering .path3:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-kitchen .path1:before {
  content: '\e965';
  color: rgb(247, 247, 248);
}

.icon-kitchen .path2:before {
  content: '\e966';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-lectures-and-discussions .path1:before {
  content: '\e967';
  color: rgb(247, 247, 248);
}

.icon-lectures-and-discussions .path2:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-lectures-and-discussions .path3:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-lectures-and-discussions .path4:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-lectures-and-discussions .path5:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-loading-dock .path1:before {
  content: '\e96c';
  color: rgb(247, 247, 248);
}

.icon-loading-dock .path2:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-meeting .path1:before {
  content: '\e96e';
  color: rgb(247, 247, 248);
}

.icon-meeting .path2:before {
  content: '\e96f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-mitzvahs .path1:before {
  content: '\e970';
  color: rgb(247, 247, 248);
}

.icon-mitzvahs .path2:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-networking-space .path1:before {
  content: '\e972';
  color: rgb(247, 247, 248);
}

.icon-networking-space .path2:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-networking-space .path3:before {
  content: '\e974';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-networking-space .path4:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-outdoor-space .path1:before {
  content: '\e976';
  color: rgb(247, 247, 248);
}

.icon-outdoor-space .path2:before {
  content: '\e977';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path1:before {
  content: '\e978';
  color: rgb(247, 247, 248);
}

.icon-press-event .path2:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path3:before {
  content: '\e97a';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path4:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path5:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path6:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path7:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-press-event .path8:before {
  content: '\e97f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-private-chef .path1:before {
  content: '\e980';
  color: rgb(247, 247, 248);
}

.icon-private-chef .path2:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-product-launch .path1:before {
  content: '\e982';
  color: rgb(255, 255, 255);
}

.icon-product-launch .path2:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-product-launch .path3:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-product-launch .path4:before {
  content: '\e985';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-product-launch .path5:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-product-launch .path6:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-public-talk .path1:before {
  content: '\e988';
  color: rgb(247, 247, 248);
}

.icon-public-talk .path2:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-rooftops .path1:before {
  content: '\e98a';
  color: rgb(247, 247, 248);
}

.icon-rooftops .path2:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-screening .path1:before {
  content: '\e98c';
  color: rgb(247, 247, 248);
}

.icon-screening .path2:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-showroom .path1:before {
  content: '\e98e';
  color: rgb(247, 247, 248);
}

.icon-showroom .path2:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-showroom .path3:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-showroom .path4:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-showroom .path5:before {
  content: '\e992';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path1:before {
  content: '\e993';
  color: rgb(247, 247, 248);
}

.icon-social-event .path2:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path3:before {
  content: '\e995';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path4:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path5:before {
  content: '\e997';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path6:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-social-event .path7:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-street-level-access .path1:before {
  content: '\e99a';
  color: rgb(247, 247, 248);
}

.icon-street-level-access .path2:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-street-level-access .path3:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-street-level-access .path4:before {
  content: '\e99d';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-street-level-access .path5:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-views .path1:before {
  content: '\e99f';
  color: rgb(247, 247, 248);
}

.icon-views .path2:before {
  content: '\e9a0';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-views .path3:before {
  content: '\e9a1';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-views .path4:before {
  content: '\e9a2';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-views .path5:before {
  content: '\e9a3';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-wedding .path1:before {
  content: '\e9a4';
  color: rgb(247, 247, 248);
}

.icon-wedding .path2:before {
  content: '\e9a5';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.icon-wedding .path3:before {
  content: '\e9a6';
  margin-left: -1em;
  color: rgb(247, 247, 248);
}

.icon-wifi .path1:before {
  content: '\e9a7';
  color: rgb(255, 255, 255);
}

.icon-wifi .path2:before {
  content: '\e9a8';
  margin-left: -1em;
  color: rgb(32, 32, 44);
}

.container {
  width: 100%;
  max-width: 1324px;
  margin: 0 auto;
  padding: 0 50px;
}

.section-block {
  margin-top: 56px;
}

.image-responsive {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.MuiPopover-paper.MuiMenu-paper {
  border-radius: 2px;
}

.text-editor {
  position: relative;
}

.quill {
  height: 75px;
}

.ql-toolbar.ql-snow {
  border: none;
  position: absolute;
  bottom: -58px;
  padding-left: 0;
}

.ql-snow .ql-tooltip {
  left: 0 !important;
  top: auto !important;
  right: 0;
  bottom: 10px;
}

.ql-container {
  font-size: 16px;
}

.MuiModal-root .MuiMenuItem-root.MuiButtonBase-root {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiModal-root .MuiMenuItem-root.MuiButtonBase-root:hover {
  overflow: visible;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  width: fit-content;
  margin-left: 16px;
  padding-right: 0;
  padding-left: 0;
}

.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option
  .MuiChip-root {
  margin: 0;
}

.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused {
  background-color: transparent;
}

.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused
  .MuiTypography-root,
.MuiAutocomplete-popper
  .MuiAutocomplete-listbox
  .MuiAutocomplete-option.Mui-focused
  .MuiChip-label {
  color: #10239e;
}

@media (max-width: 767px) {
  .container {
    padding: 0 24px;
    max-width: 600px;
  }

  .MuiPaper-root,
  .MuiPopover-paper {
    max-width: 100% !important;
    left: 0 !important;
    right: 0;
  }

  .rce-container-mbox {
    min-width: auto;
  }

  .MuiImageListItem-root {
    margin: 5px 0 !important;
  }

  .MuiTooltip-tooltip .MuiImageListItem-root {
    margin: 0 !important;
  }

  .section-block {
    margin-top: 44px;
  }

  .ql-toolbar.ql-snow {
    position: static;
  }

  .MuiAccordionDetails-root,
  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root {
    border-bottom: 1px solid #f0f0f0;
  }

  .MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters {
    min-height: 48px;
  }

  .MuiAccordion-root.Mui-expanded.MuiPaper-root {
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
