@import '~react-image-gallery/styles/css/image-gallery.css';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  scrollbar-gutter: stable both-edges;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fc .fc-button-primary,
.fc .fc-button {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
}

.fc .fc-daygrid .fc-daygrid-body .fc-day-today {
  background-color: #2f54eb;
  color: #ffffff;
}

.fc .fc-col-header-cell-cushion {
  padding: 10px;
}

.fc .fc-timegrid .fc-timegrid-slot {
  height: 40px;
}

.fc .fc-toolbar {
  justify-content: center !important;
}

.fc .fc-toolbar-title {
  font-size: 18px !important;
  font-weight: 600;
}

.fc th {
  height: 50px;
}

.fc .fc-day-today {
  background-color: transparent !important;
  color: black !important;
}

.fc th .fc-day-today {
  background-color: #2f54eb !important;
  color: #ffffff !important;
}

.selectMenu .MuiPaper-root {
  min-width: 200px !important;
  cursor: pointer;
}

.gm-style .gm-style-iw-c {
  visibility: hidden;
}
